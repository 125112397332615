.video-content {
  width: 120px;
  height: 120px;
  border: 1px dashed #d9d9d9;
  background-color: #fafafa;
}
.video-content-card {
  position: absolute;
  z-index: 999;
  top: 0;
  width: 120px;
  height: 120px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-size {
  font-size: 18px;
  color: #FFFFFF;
  cursor: pointer;
}
.icon-loading {
  font-size: 22px;
  color: #1890ff;
}
.video-loading {
  position: absolute;
  z-index: 999;
  top: 0;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
