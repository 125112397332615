/**
 * 定义布局样式，菜单导航，头部导航及底部区域
 */
body {
    background: #ececec;
}

.ant-layout-header .logo {
    width: 150px;
    height: 40px;
    border-radius: 6px;
    float: left;
    padding: 0 10px;
}

.ant-menu-horizontal {
    border-bottom: 0 !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
    background-color: #404040 !important;
    color: #ffd500 !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected:after {
    content: '';
    display: block;
    height: 3px;
    background-color: #ffd500;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
}

.ant-layout-sider {
    background: #fff;
    border-right: 1px solid #e9e9e9;
}

.ant-menu-inline, .ant-menu-vertical {
    border-right: 0;
}

.layout-header {
    background: rgb(255, 255, 255);
    padding: 0;
    border-bottom: 1px solid #e1e1e1;
}

.ant-layout-header .ant-menu-item:first-child.ant-menu-item-selected {
    background: #404040;
    color: #fff;
}

.ant-layout-header {
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
}

.ant-layout-footer {
    padding: 0;
    text-align: center;
    height: 30px;
    line-height: 30px;
}

.ant-menu-item-group-title {
    padding-left: 48px !important;
    color: #777 !important;
}

.ant-menu-vertical .ant-menu-item-group-title {
    padding-left: 16px !important;
    color: #777 !important;
}

.ant-menu-vertical .ant-menu-item-group-list .ant-menu-item {
    padding-left: 16px !important;
}

.ant-menu-inline .ant-menu-item-group-list .ant-menu-item {
    height: 28px;
    line-height: 28px;
}

.layout-setting {
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 0;
    color: #fff;
}

.btn-logout {
    margin-left: 15px;
    color: #fff;
}

.layout-setting.bg-white {
    color: #999;
}

.layout-setting.bg-white .btn-logout {
    color: #999;
}

.setting-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
