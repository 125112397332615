/**
 * 定义通用UI及工具样式
 */
.hide {
  display: none !important;
}
.visible-hidden {
  visibility: hidden;
}
.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.margin16 {
  margin: 16px;
}
.margin20 {
  margin: 20px;
}
/*margin-top*/
.mt-10 {
  margin-top: -10px;
}
.mt3 {
  margin-top: 3px;
}
.mt4 {
  margin-top: 4px;
}
.mt5 {
  margin-top: 5px;
}
.mt7 {
  margin-top: 7px;
}
.mt8 {
  margin-top: 8px;
}
.mt10 {
  margin-top: 10px !important;
}
.mt14 {
  margin-top: 14px;
}
.mt15 {
  margin-top: 15px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mt36 {
  margin-top: 36px;
}
.mt38 {
  margin-top: 38px;
}
.mt40 {
  margin-top: 40px;
}
.mt42 {
  margin-top: 42px;
}
/*margin-right*/
.mr5 {
  margin-right: 5px;
}
.mr8 {
  margin-right: 8px;
}
.mr10 {
  margin-right: 10px;
}
.mr15 {
  margin-right: 15px;
}
.mr20 {
  margin-right: 20px !important;
}
.mr26 {
  margin-right: 26px;
}
.mr30 {
  margin-right: 30px;
}
.mr32 {
  margin-right: 32px;
}
.mr120 {
  margin-right: 120px;
}
/*margin-left*/
.ml-10 {
  margin-left: -10px;
}
.mlr-20 {
  margin: 0 -20px;
}
.ml-6 {
  margin-left: -6px;
}
.ml-80 {
  margin-left: -80px;
}
.ml-60 {
  margin-left: -60px;
}
.ml-20 {
  margin-left: -20px;
}
.ml3 {
  margin-left: 3px;
}
.ml5 {
  margin-left: 5px;
}
.ml6 {
  margin-left: 6px;
}
.ml7 {
  margin-left: 7px;
}
.ml10 {
  margin-left: 10px;
}
.ml12 {
  margin-left: 12px;
}
.ml15 {
  margin-left: 15px;
}
.ml18 {
  margin-left: 18px;
}
.ml20 {
  margin-left: 20px;
}
.ml25 {
  margin-left: 25px;
}
.ml28 {
  margin-left: 28px;
}
.ml30 {
  margin-left: 30px;
}
.ml34 {
  margin-left: 34px;
}
.ml36 {
  margin-left: 36px;
}
.ml40 {
  margin-left: 40px;
}
.ml45 {
  margin-left: 45px;
}
.ml50 {
  margin-left: 50px;
}
.ml52 {
  margin-left: 52px;
}
.ml55 {
  margin-left: 55px;
}
.ml70 {
  margin-left: 70px;
}
.ml75 {
  margin-left: 75px;
}
.ml90 {
  margin-left: 90px;
}
.ml120 {
  margin-left: 120px;
}
.ml200 {
  margin-left: 200px;
}
/*margin-bottom*/
.mb5 {
  margin-bottom: 5px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb15 {
  margin-bottom: 15px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mb25 {
  margin-bottom: 25px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.mb37 {
  margin-bottom: 37px !important;
}
.mb40 {
  margin-bottom: 40px;
}
.pl15 {
  padding-left: 15px;
}
.pl5 {
  padding-left: 5px;
}
.text-left {
  text-align: left;
}
.pl20 {
  padding-left: 20px;
}
.pl40 {
  padding-left: 40px;
}
.border-bottom-ccc {
  border-bottom: 1px solid #ccc;
}
.border-bottom-dotted-black {
  border-bottom: 1px dotted #999;
}
.border-bottom-black {
  border-bottom: 1px solid #000;
}
.border-top-ccc {
  border-top: 1px solid #ccc;
}
.border-ccc {
  border: 1px solid #ccc;
}
.border-black {
  border: 1px solid #000;
}
.padding-10 {
  padding: 10px;
}
.padding-15 {
  padding: 15px;
}
.padding-20 {
  padding: 20px;
}
.padding-top-5 {
  padding-top: 5px;
}
.padding-top-10 {
  padding-top: 10px;
}
.padding-top-15 {
  padding-top: 15px;
}
.padding-top-20 {
  padding-top: 20px;
}
.padding-top-30 {
  padding-top: 30px;
}
.padding-top-75 {
  padding-top: 75px;
}
.padding-tb-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.padding-bottom-10 {
  padding-bottom: 10px;
}
.padding-bottom-5 {
  padding-bottom: 5px;
}
.padding-l-10 {
  padding-left: 10px;
}
.padding-l-20 {
  padding-left: 20px;
}
.padding-r-20 {
  padding-right: 20px;
}
.padding-r-10 {
  padding-right: 10px;
}
.font-size-6 {
  font-size: 6px !important;
  -webkit-transform: scale(0.6);
}
.font-size-8 {
  font-size: 8px !important;
  -webkit-transform: scale(0.8);
}
.font-size-10 {
  font-size: 10px !important;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-13 {
  font-size: 13px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-15 {
  font-size: 15px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-22 {
  font-size: 22px;
}
.font-size-24 {
  font-size: 24px;
}
.font-size-30 {
  font-size: 30px;
}
.font-size-36 {
  font-size: 36px;
}
.font-wight-bold {
  font-weight: bold;
}
.no-padding {
  padding: 0 !important;
}
.font-c-white {
  color: #fff;
}
.c-grey {
  color: #333;
}
.grey-999 {
  color: #999;
}
.grey-9c9c9c {
  color: #9c9c9c;
}
.bg-color-fafafa {
  background-color: #fafafa;
}
.c-grey-c {
  color: #ccc;
}
.strong {
  font-weight: 600;
}
.text-default {
  color: #333;
}
.text-white {
  color: #fff;
}
.text-gray {
  color: #666;
}
.text-gray-999 {
  color: #999;
}
.text-red {
  color: #f50;
}
.text-blue {
  color: #2db7f5;
}
.text-disabled {
  color: rgba(0, 0, 0, 0.25);
}
.text-success {
  color: #00A854;
}
.text-delete {
  text-decoration: line-through;
}
.text-print {
  color: #000 !important;
  font-weight: bold;
}
.text-print label {
  color: #000 !important;
  font-weight: bold;
}
.text-print-inkjet {
  color: #666666 !important;
  font-weight: 400 !important;
}
.text-print-inkjet label {
  color: #666666 !important;
  font-weight: 650 !important;
}
.text-bold {
  font-weight: 500;
}
.bg-light-blue {
  background-color: #ecf6fd;
}
.help-icon-font {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  margin-left: 5px;
}
.response-img {
  max-width: 100%;
}
.overflow-hidden {
  overflow: hidden;
}
.shuidao-btn-tiny {
  height: 18px;
  line-height: 18px;
  font-size: 12px !important;
}
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.relative {
  position: relative;
}
.width-80 {
  width: 80px;
}
.width-100 {
  width: 100px;
}
.width-120 {
  width: 120px;
}
.width-130 {
  width: 130px;
}
.width-150 {
  width: 150px;
}
.width-180 {
  width: 180px;
}
.width-200 {
  width: 200px;
}
.width-240 {
  width: 240px;
}
.width-250 {
  width: 250px;
}
.width-260 {
  width: 260px;
}
.width-280 {
  width: 280px;
}
.width80-percent {
  width: 80%;
}
.width100-percent {
  width: 100%;
}
.width90-percent {
  width: 90%;
}
.backgroundG-gray {
  background-color: rgb(250 250 250);
}
.background-gray {
  background: #999999;
}
.border-color-grey {
  border: 1px solid #ebebeb;
}
.border-radius-4 {
  border-radius: 4px;
}
.inline-block {
  display: inline-block !important;
}
.blue-line-color {
  color: #108ee9;
}
.btn-arrow {
  color: #999;
}
.btn-arrow:hover {
  color: #108ee9;
  cursor: pointer;
}
.action-delete:hover {
  color: #f04134;
}
.pointer {
  cursor: pointer;
}
.module-head {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  height: 60px !important;
}
.with-bottom-divider {
  padding-bottom: 20px;
  border-bottom: 1px dotted #e5e5e5;
}
.with-top-divider {
  padding-top: 20px;
  border-top: 1px dotted #e5e5e5;
}
.with-bottom-divider:last-of-type {
  border-bottom: 0;
}
.bottom-divider-dotted {
  border-bottom: 1px dotted #e5e5e5;
}
.text-center {
  display: flex !important;
  align-items: center;
}
.form-line-divider {
  height: 1px;
  border-bottom: 1px dotted #e5e5e5;
  margin: 3px 0 12px;
}
.icon-first-name {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #9fd46e;
  border-radius: 6px;
  font-size: 24px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  line-height: 50px;
}
.icon-first-name-three {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #9fd46e;
  border-radius: 6px;
  font-size: 24px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  line-height: 50px;
}
.icon-first-name-none {
  background-color: #d9d9d9;
}
.tag {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  font-size: 11px;
  color: #333;
  text-align: center;
  padding: 0 5px;
  border-radius: 2px;
  background-color: #ffd700;
}
.text-decoration-none {
  text-decoration: none;
}
.label:after {
  content: ":";
  margin: 0 8px 0 2px;
  position: relative;
  top: -0.5px;
}
.label-right {
  display: inline-block;
  min-width: 110px;
  text-align: right;
}
.label-right-lg {
  min-width: 150px;
}
.label-middle {
  width: 70px;
  display: inline-block;
  text-align: right;
}
.label-large {
  width: 84px;
  display: inline-block;
  text-align: right;
}
.info-line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-right: 10px;
}
.info-line > .label {
  min-width: 150px;
  text-align: right;
}
.line-middle {
  display: flex;
  align-items: center;
}
.line-middle .ant-form-item {
  margin-bottom: 0;
}
.ant-modal-full .ant-modal-body {
  padding: 0;
}
.ant-modal-full .ant-collapse {
  border-radius: 0;
  border-top: 0;
}
.ant-modal-full .ant-form {
  margin: 20px 30px 0 30px;
}
.ant-modal-full .ant-form.form-collapse {
  margin: 0;
}
.ant-modal-full .ant-form.form-collapse .form-action-container {
  margin: 0;
  border: 0;
}
.ant-modal-full .form-action-container {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e5e5e5;
  margin: 0 -30px;
}
.align-left label {
  min-width: 100px;
  text-align: right;
  display: inline-block;
}
.render-content {
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  background: #f3f3f3;
}
.render-content > div,
.render-content .line-block {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}
.render-content > div:first-child,
.render-content .line-block:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.number-input {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.number-input-addon {
  display: inline-block;
  font-size: 14px;
  width: 27px;
  height: 32px;
  line-height: 32px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.65);
  text-align: center;
  background-color: #eee;
  border: 1px solid #d9d9d9;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-transition: all 0.3s;
  border-left: 0;
  vertical-align: top;
  position: absolute;
  top: 4px;
  right: 0;
}
.triangle-handstand {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #49a9ee;
  margin-left: 5px;
  margin-bottom: 2px;
}
.triangle {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 6px solid #49a9ee;
  margin-left: 5px;
  margin-bottom: 2px;
}
.line-height32-middle {
  display: inline-block;
  line-height: 32px;
  vertical-align: middle;
}
.triangle-border {
  position: relative;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 26px 26px 26px;
  border-color: transparent transparent #ffd700 transparent;
}
.clearfix:after {
  content: '';
  display: block;
  clear: both;
}
.table-nowrap .ant-table table {
  table-layout: fixed !important;
}
.table-nowrap .ant-table td,
.table-nowrap .ant-table th {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.position-relative {
  position: relative;
}
.color-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
}
.color-square {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 0;
  margin-left: 5px;
}
.alipay-icon {
  color: #1890ff;
}
.wechat-icon {
  color: #52c41a;
}
.table-icon {
  width: 18px;
  height: 18px;
}
.flex-row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flex-center-column {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.flex-right {
  display: flex;
  justify-content: flex-end;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex-left {
  display: flex;
  justify-content: flex-start;
}
.editable-row {
  padding: 4px;
}
.editable-row:hover {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px;
}
.ant-form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.trackpoint_in {
  height: 6px;
  width: 6px;
  background-color: #2398ff;
  border-radius: 4px;
  margin: 3px;
}
.trackpointRed_in {
  height: 6px;
  width: 6px;
  background-color: #FF3333;
  border-radius: 4px;
  margin: 3px;
}
.trackpointOverlay .trackpoint_in {
  display: none;
}
.trackpointonOverlay .trackpoint_in {
  display: block;
}
.trackpointOverlay,
.trackpointonOverlay {
  /*cursor: pointer;*/
  position: absolute;
  z-index: 50;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px 12px;
  border-radius: 8px;
  cursor: pointer;
}
.trackpointOverlay {
  height: 12px;
  width: 12px;
  background-color: #fff;
  border: 2px solid #000;
  cursor: pointer;
}
.trackpointonOverlay {
  height: 14px;
  width: 14px;
  background-color: #fff;
  border: 1px solid #2398ff;
  cursor: pointer;
}
.trackpointonOverlayRed .trackpointRed_in {
  display: block;
}
.trackpointonOverlayRed {
  height: 14px;
  width: 14px;
  background-color: #FFFFFF;
  border: 1px solid #FF3333;
  cursor: pointer;
}
.trackpointonOverlayRed {
  /*cursor: pointer;*/
  position: absolute;
  z-index: 50;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px 12px;
  border-radius: 8px;
}
.shuidao-input-wrapper {
  display: flex;
}
.shuidao-input-wrapper > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content {
  display: flex;
}
.shuidao-input-suffix {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  white-space: nowrap;
  display: flex;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  align-items: center;
}
.map-style {
  min-height: 650px;
}
