/**
 * 定义需要覆盖已有样式的样式，主要是覆盖antd的样式
 * @desc 放在页面最末尾，尽量减少使用important
 */

body {
    font-size: 14px !important;
    color: #333333 !important;
}

body > a {
    display: none;
}

.ant-table {
    color: #333333 !important;
}

.ant-breadcrumb {
    font-size: 14px !important;
    color: #333333 !important;
}

label {
    font-size: 14px !important;
    color: #333333 !important;
}

.ant-select-dropdown-menu-item {
    color: #333333 !important;
}

.ant-radio-button-wrapper {
    font-size: 14px !important;
    color: #333333 !important;
}

.ant-radio-button-checked {
    color: #108ee9 !important;
}

.ant-radio-button-wrapper-checked {
    color: #108ee9 !important;
}

.ant-select-dropdown {
    font-size: 14px !important;
}

.ant-select {
    font-size: 14px !important;
    color: #333333 !important;
}

.ant-input {
    font-size: 14px !important;
    color: #333333 !important;
}

.ant-card-head-title {
    font-size: 16px !important;
}


.ant-badge-status-text {
    font-size: 14px !important;
    color: #333333 !important;
}

.ant-badge-status-text {
    font-size: 14px !important;
    color: #333333 !important;
}

.badge-size-10 .ant-badge-status-text {
    font-size: 10px !important;
}

.ant-form-item {
    color: #333333;
    /*margin-bottom: 10px;*/
}

.inline-form .ant-form-item {
    margin-bottom: 0 !important;
}

/*todo form 表单间距太大的问题，需要解决错误信息提示如何展示的问题 */
/*.ant-form-item .has-error .ant-form-explain {*/
/*display: inline-block;*/
/*position: absolute;*/
/*right: 10px;*/
/*top: 12px;*/
/*}*/

.ant-btn {
    font-size: 14px;
}

.ant-input-group-addon {
    font-size: 14px !important;
}

/*导航icon动画*/

.ant-menu-submenu-title .anticon {
    -webkit-transition: null !important;
    transition: null !important;
}

.ant-menu-root {
    font-size: 15px !important;
}

.ant-menu-item {
    font-size: 14px !important;
}

.ant-menu-item:first-child,
.ant-menu-item:last-child {
    border-radius: 0 0 0 0 !important;
}

.ant-timeline-item-content {
    font-size: 14px !important;
}

/* todo 影响了部分样式 */
.ant-dropdown-menu-item {
    /*padding: 7px 2px !important;*/
    font-size: 14px;
}

.ant-select-selection-selected-value {
    width: 100%;
}

.ant-input-number-input {
    font-size: 14px;
}

.ant-alert-description,
.ant-select-tree {
    font-size: 14px !important;
}

.ant-alert-description,
.ant-calendar-input,
.ant-calendar-date,
.ant-calendar-ym-select,
.ant-cascader-menu,
.ant-cascader-picker-label {
    font-size: 14px;
}

.ant-table-small .ant-table-header table {
    border-bottom: none !important;
}

.ant-calendar-month-panel-body {
    line-height: 3 !important;
}

.ant-alert {
    font-size: 14px !important;
}

.ant-btn-lg {
    height: 30px;
}

textarea.ant-input {
    max-width: none !important;
}

.ant-table-middle .ant-table-thead > tr > th:not(.ant-table-selection-column),
.ant-table-middle .ant-table-tbody > tr > td {
    padding: 2px 8px;
    height: 39px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
    padding: 2px 8px;
    height: 39px;
}

.ant-input-group-wrapper {
    width: 100%;
}

/**
* Modal
**/

/*模态框和表单的操作按钮居中显示*/
.ant-modal-footer {
    text-align: center !important;
}

.ant-tag-checkable {
    border: 1px solid #ccc !important;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td, hr, button, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    margin: 0;
    padding: 0;
}

ul,
ol {
    list-style: none;
}

/* 重写系统列表选中td的宽度*/
.ant-table-thead > tr > th.ant-table-selection-column, .ant-table-tbody > tr > td.ant-table-selection-column {
    min-width: 0 !important;
    width: 30px !important;
}

/* 重写系统中列表*/
.ant-menu-item-group-title {
    font-size: 12px !important;
}

.info-panel .ant-form-item {
    margin-bottom: 0;
}

/*重置Select的箭头点击响应样式，以解决在深层模态框表单中点击下拉框箭头，模态框关闭的问题*/
/*待antd解决该问题后，即可删除 https://github.com/ant-design/ant-design/issues/12294 */
.ant-select-selection--single {
    pointer-events: none;
}

.with-nested-table .ant-table-expanded-row > td:first-child {
    border-right: 0;
}

.with-nested-table .ant-table-expanded-row > td:last-child {
    padding: 0;
}

.with-nested-table .ant-table-expanded-row > td:last-child table {
    border: 0;
}

.with-nested-table .ant-table-expanded-row > td:last-child table .ant-table-thead > tr > th {
    background-color: #f7f7f7;
    font-weight: normal;
    border-right: 0;
}

.with-nested-table .ant-table-expanded-row > td:last-child table .ant-table-tbody > tr > td {
    border-right: 0;
}

.with-nested-table .ant-table-expanded-row > td:last-child .ant-table-thead th {
    border-bottom: 1px solid #e9e9e9;
}

.with-nested-table .ant-table-expanded-row > td:last-child .ant-table-thead th:first-child {
    padding-left: 0;
}

.with-nested-table .ant-table-expanded-row > td:last-child .ant-table-expanded-row td:first-child {
    padding-left: 0;
}

.with-nested-table .ant-table-expanded-row:last-child td {
    border: none;
}

.with-nested-table .ant-table-expanded-row .ant-table-thead > tr > th {
    background: none;
}

.with-nested-table tr.ant-table-expanded-row td > .ant-table-wrapper{
    margin: 0;
}

.with-nested-table .ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table {
    margin: 0;
    z-index: 9;
}

.with-nested-table .table-operation a:not(:last-child) {
    margin-right: 24px;
}

.table-with-tiny-padding .ant-table-thead > tr > th,
.table-with-tiny-padding .ant-table-tbody > tr > td {
    padding: 2px 6px !important;
}
